#hero {
  a {
    display: inline-block;
    justify-content: center;
    padding: 10px;
    transition: transform 0.5s;
    &:focus,
    &:hover {
      background-color: $buttonHoverOne;
      outline: 2px solid $buttonOutlineOne;
      outline-offset: 2px;
      transform: scale(0.8);
    }
  }
}
