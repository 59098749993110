#portfolio {
  background-color: $yellowOne;
  padding: 20px;
  h1 {
    color: $secondaryTextColor;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 20px;
  }
  p {
    color: $secondaryTextColor;
  }
  h2 {
    color: $mainTextColor;
  }

  .card {
    background-color: $yellowTwo;
    padding: 5px;
    img {
      border-radius: 10%;
      padding: 20px;
    }
    &:focus,
    &:hover {
      filter: brightness(85%);
      transform: translateY(-10px);
    }
  }
}
