.navbar {
  background-color: $purpleOne;
  color: $whiteOne;
}

.header-navigation-bar-button {
  margin-right: 10px;
  padding: 5px;

  &:focus,
  &:hover {
    background-color: $buttonHoverOne;
  }
}

.menu-item {
  background-color: $purpleOne;
  padding: 10px;
  &:focus,
  &:hover {
    background-color: $buttonHoverOne;
  }
}

.menu-item:first-child {
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
}
.menu-item:last-child {
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
}

#headerLogo {
  max-width: 200px;
}
