$blackOne: #000;
$blackTwo: #030202;
$blueOne: #0d1c5e;
$greenOne: #a8bbc0;
$greenTwo: #839b88;
$greenThree: #092c11;
$pinkOne: #cfa19f;
$purpleOne: #622574;
$purpleTwo: #925b71;
$purpleThree: #9382c4;
$whiteOne: #fff;
$yellowOne: #d0c7af;
$yellowTwo: #b6ab87;

$buttonHoverOne: $purpleTwo;
$buttonOutlineOne: $blackOne;
$mainTextColor: $blueOne;
$secondaryTextColor: $blackTwo;
$thirdTextColor: $whiteOne;
