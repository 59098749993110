footer {
  background-color: $pinkOne;
  color: $blackOne;
  margin: 0;
  padding: 10px 0;
  row-gap: 1rem;
  text-align: center;
  width: 100%;

  .copyright,
  .connections {
    font-weight: bold;
  }

  .social-links {
    display: flex;
    a {
      display: inline-block;
      justify-content: center;
      padding: 10px;
      transition: transform 0.5s;

      &:focus,
      &:hover {
        background-color: $buttonHoverOne;
        outline: 2px solid $buttonOutlineOne;
        outline-offset: 2px;
        transform: scale(0.8);
      }
    }
  }
}
